<template>

    <div class="crud-create-external-wrapper">
        <div class="admin-view-toolbar " style="margin-bottom: 40px;">

            <div class="uk-flex uk-flex-between">
                <div class="start-side">
                    <form-button
                            icon="check"
                            type="primary"
                            class="uk-margin-small-right"
                            :showSlot="false"
                            @click="save"
                            :uk-tooltip="translateTitleCase('core.crud.createSaveTooltip')"
                    ></form-button>

                    <form-button
                            icon="plus"
                            type="primary"
                            class="uk-margin-small-right"
                            :showSlot="false"
                            :uk-tooltip="translateTitleCase('core.crud.createAndNewTooltip')"
                            @click="saveAndNew"
                    ></form-button>

                    <form-button
                            icon="copy"
                            type="primary"
                            :showSlot="false"
                            :uk-tooltip="translateTitleCase('core.crud.createCopyTooltip')"
                            @click="saveAndCopy"
                    ></form-button>
                </div>
                <div class="end-side">

                </div>
            </div>
        </div>

        <div class="crud-create-content">
            <base-form
                    ref="createForm"
                    :action="formAction"
                    method="post"
                    v-model="item"
                    :formPath="formPath"
                    :config="{context: 'create'}"
                    @form:submitSuccess="afterSubmit"
            ></base-form>

        </div>
    </div>


</template>

<script>
    export default {
        props: {

            formPath: {
                type: String,
                default: false,
            },
            formAction: {
                type: String,
                default: false,
            },
            successRedirect: {
                type: Object,
                default: {name: 'admin-dashboard'}
            }
        },
        data: function () {
            return {
                item: {}
            };
        },
        computed: {

        },
        methods: {
            // successful submit handler
            afterSubmit:  function (result) {
                this.$saffron.ui.notification('<span uk-icon="check"></span>'+this.translateSafe('core.crud.createdSuccess'), 'success');
            },
            // save button - trigger submit. after normal submit action, if successfull, go back to list
            save :  async function () {
                let result;

                try {
                    result = await this.$refs.createForm.handleSubmit();
                } catch (e) {
                    return;
                }

                if (result) {
                    this.$router.push(this.successRedirect);
                }

            },
            saveAndNew: async function() {
                let result;

                try {
                    result = await this.$refs.createForm.handleSubmit();
                } catch (e) {
                    return;
                }

                if (result) {
                    this.$refs.createForm.clear();
                }
            },
            saveAndCopy: async function() {
                let result;

                try {
                    result = await this.$refs.createForm.handleSubmit();
                } catch (e) {
                    return;
                }

                if (result) {
                    this.$saffron.ui.notification('<span uk-icon="check"></span>'+this.translateSafe('core.crud.saveAndCopySuccess'), 'success');
                }
            }
        }

    }
</script>

<style scoped lang="scss">

</style>
